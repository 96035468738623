<template>
  <div class="meeting-page">
    <div class="page-header">
        <div class="pre-btn" @click="handlePreWeek">
            <div class="van-icon van-icon-arrow-left"></div>上周
        </div>
        <div class="time-range" @click="showCalendar = true">
          {{dateRange.startTime}} 至 {{dateRange.endTime}}
        </div>
        <van-calendar title="会议日期选择" v-model="showCalendar" type="range" :formatter="formatter" :min-date="minDate" :default-date="defaultDate" :allow-same-day="true" color="#1989fa" @confirm="onConfirm" />
        <div class="next-btn" @click="handleNextWeek">
            下周
            <div class="van-icon van-icon-arrow"></div>
        </div>
    </div>
    <div class="table-wrapper" v-show="meetingSumList.length">
      <div class="table-title center">领导一周工作安排表</div>
      <table class="table" border="0" cellspacing="0" cellpadding="0" width="100%" bordercolor="#dfdfdf">
        <tr class="table-header">
          <td class="center">日期</td>
          <td class="center" colspan="2">时间</td>
          <td class="center">内容</td>
          <td class="center">地点</td>
          <td class="center">参加人员</td>
          <td class="center">备注</td>
        </tr>
        <template v-for="(weekItem, index1) in meetingSumList">
          <template v-for="(dayItem, index2) in weekItem.data">
            <tr v-for="(item, index3) in dayItem.data" :key="'' + index1 + index2 + index3">
              <td width="45" class="center" :rowspan="weekItem.length" v-show="![index2, index3].some(num => num > 0)">
                {{item.startTime | timeFormat('{m}月{d}')}}
                <br />
                {{item.startTime | timeFormat('星期{a}')}}
              </td>
              <td width="32" class="center" :rowspan="dayItem.length" v-show="index3 === 0">{{dayItem.key}}</td>
              <td width="32">{{item.startTime | timeFormat('{h}:{i}')}}</td>
              <td class="center">{{item.title}}</td>
              <td width="50" class="center">{{item.activeArea}}</td>
              <td width="70" class="center"><a style="font-size:0.13rem" :title="item.leaderUser">{{item.leaderUser}}</a></td>
              <td class="center" width="40">{{item.remark}}</td>
            </tr>
          </template>
        </template>
      </table>
    </div>
    <div v-show="!meetingSumList.length" class="none-block center">此周暂无工作安排</div>
	<!-- <div class="add-btn" @click="toMyMeeting">
		<image src="../../static/add-icon.png"></image>
	</div> -->
	
  </div>
</template>
<script>
import { parseTime } from '@/utils/index'
import { getLeaderWorkList , getMyWorkList } from '@/api/index'
// 日期插件js
import Calendar from '@/utils/calendar'
export default {
  components: {},
  data() {
    return {
      loading: true,
      leaderWorkList: [],
      weeksNum: 0,
	  joinDetail: {},
	  joinUserDetail: [],
	  joinDeptDetail: [],
      showCalendar:false,
      minDate: new Date(1970, 0, 1),
    }
  },
  computed: {
    defaultDate(){
        let currentDate = Date.now() + this.weeksNum * 7 * 24 * 36e5
        let currentDay = new Date(currentDate).getDay() || 7
        let startTime = currentDate - (currentDay - 1) * 24 * 36e5
        let endTime = currentDate + (7 - currentDay) * 24 * 36e5
        return [new Date(startTime),new Date(endTime)]
    },
    dateRange() {
      let currentDate = Date.now() + this.weeksNum * 7 * 24 * 36e5
      let currentDay = new Date(currentDate).getDay() || 7
      let startTime = currentDate - (currentDay - 1) * 24 * 36e5
      let endTime = currentDate + (7 - currentDay) * 24 * 36e5
      const dateRange = {
        startTime: parseTime(new Date(startTime), '{y}-{m}-{d}'),
        endTime: parseTime(new Date(endTime), '{y}-{m}-{d}')
      }
      return dateRange
    },
    meetingSumList() {
      let meetingSumList = []
      let meetingMap = {}
      this.leaderWorkList.forEach(item => {
        item.meetingDay = parseTime(item.startTime, '{y}-{m}-{d}')
        meetingMap[item.meetingDay] = meetingMap[item.meetingDay] || []
        meetingMap[item.meetingDay].push(item)
      })
      for (let key in meetingMap) {
        let meetingMapItem = meetingMap[key] || []
        meetingMapItem.sort((x, y) => {
          return new Date(x).getTime() - new Date(y).getTime()
        })
        let dayMeetingMap = {}
        meetingMapItem.forEach(item => {
          const hours = new Date(item.startTime).getHours()
          let dayKey = '晚上'
          const dayRangeMap = {
            12: '上午',
            14: '中午',
            18: '下午'
          }
          const dayRangeKeyMap = Object.keys(dayRangeMap).sort()
          for (var i = 0, keyMapLen = dayRangeKeyMap.length; i < keyMapLen; i++) {
            if (hours < dayRangeKeyMap[i]) {
              dayKey = '上午'
              break
            }
          }
          dayMeetingMap[dayKey] = dayMeetingMap[dayKey] || []
          dayMeetingMap[dayKey].push(item)
        })
        let dayMeetingList = []
        for (let dayMapKey in dayMeetingMap) {
          dayMeetingList.push({
            key: dayMapKey,
            length: dayMeetingMap[dayMapKey].length,
            data: dayMeetingMap[dayMapKey]
          })
        }
        meetingSumList.push({
          key,
          length: meetingMap[key].length,
          data: dayMeetingList
        })
      }
      return meetingSumList || []
    }
  },
  created() {
    this.handleLeaderWorkList()
  },
  methods: {
    onConfirm(date){
        const [startTime, endTime] = date;
        this.showCalendar = false;
        this.dateRange.startTime = parseTime(new Date(startTime), '{y}-{m}-{d}')
        this.dateRange.endTime = parseTime(new Date(endTime), '{y}-{m}-{d}')
        this.handleLeaderWorkList();
    },
    formatter(day) {
      const _time = new Date(day.date);
      const y = _time.getFullYear();
      const m = _time.getMonth() + 1;
      const d = _time.getDate();
      const w = _time.getDay();
      const info = Calendar.solar2lunar(y, m, d);
      //   改变周六周日显示颜色
      if (w === 0 || w === 6) {
        day.className = "weekendRed";
      }
      //   优先级:节日>节气>农历
      if (info.festival != null && info.festival != "") {
        day.bottomInfo = info.festival;
      } else if (info.Term != null && info.Term != "") {
        day.bottomInfo = info.Term;
      } else if (info.IDayCn != null && info.IDayCn != "") {
        day.bottomInfo = info.IDayCn;
      }
      return day;
    },
	meetingDetail(item){
		let params = {
			startTime: item.startTime,
			endTime: item.endTime,
			roomName: item.roomName,
			applyId: item.applyId
		}
		// uni.navigateTo({
		//   url: '/pageMeeting/joinDetail/joinDetail?params=' + JSON.stringify(params)
		// })
	},
    handleLeaderWorkList() {
        getLeaderWorkList(this.dateRange).then((res)=>{
            if(res.code==200){
                this.leaderWorkList = res.data;
            }
        })
    },
    handlePreWeek() {
      this.weeksNum--
      this.handleLeaderWorkList()
    },
    handleNextWeek() {
      this.weeksNum++
      this.handleLeaderWorkList()
    },
    // handleChangeView() {
    //   uni.navigateTo({
    //     url: '/pageMeeting/main/main'
    //   })
    // },
	// toMyMeeting(){
	// 	uni.navigateTo({
	// 	  url: '/pageMeeting/list/list'
	// 	})
	// }
  },
  filters: {
    timeFormat(val, pattern = '{y}-{m}-{d} {h}:{i}:{s}') {
      return parseTime(val, pattern)
    }
  }
}
</script>

<style lang="scss">
.center {
  text-align: center;
}
.meeting-page {
  display: flex;
  flex-direction: column;
  color: #444;
  max-height: 100vh;
  .page-header {
    flex: 0;
    padding: 0.12rem;
    border-bottom: 0.01rem solid #ddd;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .iconBtn {
      font-size: 0.18rem ;
    }
    .pre-btn,
    .next-btn {
      font-size: 0.15rem;
      line-height: 0.30rem;
      text-align: center;
      color: gray;
    }
  }
  .table-wrapper {
    flex: 1;
    padding: 0rem 0.05rem;
    .table-title {
      font-weight: 700;
      font-size: 0.16rem;
      padding: 0.06rem;
    }
    .table {
      border-collapse: collapse;
      font-size: 12px;
      .table-header td {
        padding: 6px 3px;
        font-weight: 700;
        background-color: #fafafa;
        color: #888888;
        border-bottom: 0;
      }
      td {
        padding: 3px;
        border: 0.001rem solid #dfdfdf;
        word-break: break-all;
      }
    }
  }
  .none-block {
    font-size: 0.30rem;
    color: #ddd;
    height: calc(100vh - 50px);
    line-height: calc(100vh - 50px);
  }
}
</style>
